import axios from 'axios';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { url } from '../../data/url';

export function useApiGetId() {
    const [data, setData] = useState([]);
    const [table, setTable] = useState(false);
    const [loading, setLoading] = useState(false);

    const searching = async (tabla, nameUno, initialDate, endDate, nameDos, campana, nameVUser, UserDocume, num_doc, phoneNumber, name_userId) => {
        setLoading(true); // Mostrar el loader

        try {
            if (num_doc !== "") {
                const [res, res2] = await Promise.all([
                    axios.get(
                        `${url}/search/${tabla}/${phoneNumber}/${num_doc}`
                    ),
                    axios.get(
                        `${url}/search/${tabla}/${name_userId}/${num_doc}`
                    ),
                ]);

                let dataTyper = [];

                if (res.data.rows) dataTyper = [...dataTyper, ...res.data.rows];
                if (res2.data.rows) dataTyper = [...dataTyper, ...res2.data.rows];

                setData(dataTyper);
                setTable(dataTyper.length > 0);
                Swal.fire(
                    dataTyper !== undefined
                        ? {
                            position: "top-end",
                            icon: "info",
                            toast: true,
                            title: dataTyper.length + " resultados",
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: 3000,
                        }
                        : {
                            position: "top-end",
                            icon: "error",
                            toast: true,
                            title:
                                "Los datos no se encuentran registrados en el sistema",
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: 3000,
                        }
                );

            } else {
                const res = await axios.get(
                    `${url}/searchAll/${tabla}/${nameUno}/${initialDate}/${endDate}/${nameDos}/${campana}/${nameVUser}/${UserDocume}`,
                );


                if (res.data.rows !== undefined && res.data.rows.length > 0) {
                    setData(res.data.rows);
                    setTable(true);
                } else {
                    setData([]);
                    setTable(false);
                }
                Swal.fire(res.data.err ? res.data.err : res.data.msg);
            }

        } catch (error) {
            console.log("Ocurrio un error: " + error);
            Swal.fire("Error");
        }
        setLoading(false); // Ocultar el loader
    };

    return { data, setData, table, setTable, searching, loading };

}